<template>
  <div class="h-full overflow-auto editor">
    <VueMonacoEditor
      v-model:value="code"
      :language="currentLanguage"
      :theme="currentTheme"
      :options="MONACO_EDITOR_OPTIONS"
      @mount="handleMount"
      @change="handleChange"
    />
  </div>
</template>
<script>
import { VueMonacoEditor } from "@guolao/vue-monaco-editor";

export default {
  name: "TemplateEditor",
  components: {
    VueMonacoEditor,
  },
  props: {
    template: {
      type: String,
      default: () => "",
    },
    lang: {
      type: String,
      default: () => "js",
    },
    currentTheme: {
      type: String,
      default: () => "vs-dark",
    },
    currentLanguage: {
      type: String,
      default: () => "html",
    },
  },
  data() {
    return {
      MONACO_EDITOR_OPTIONS: {
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true,
        tabSize: 2,
        insertSpaces: true,
        detectIndentation: false,
        autoIndent: "full",
        minimap: { enabled: true },
      },
      editorRef: null,
    };
  },
  computed: {
    code: {
      get() {
        return this.template;
      },
      set(v) {
        this.$emit("set", v);
      },
    },
  },
  methods: {
    /**
     * Handles the mount event of the editor.
     * @param {Editor} editor - The editor instance.
     */
    handleMount(editor) {
      if (editor) {
        this.editorRef = editor;
        editor.focus();
      }
    },

    /**
     * Handles the change event of the editor.
     * @param {string} value - The new value of the editor.
     */
    handleChange(value) {
      this.$emit("set", value);
    },
  },
};
</script>
<style scoped>
.editor {
  background: #ffffff;
  color: #000;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
}
</style>
