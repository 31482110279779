<template>
  <div class="flex flex-col">
    <!-- Response Section -->
    <div class="py-1 flex items-center gap-x-4">
      <label class="text-gray-700 font-medium text-xs"
        >Response Section :</label
      >
      <input
        v-if="item?.resolve?.section"
        :value="item.resolve.section"
        type="text"
        class="w-3/6 px-3 py-1 border border-gray-300 custom-focus rounded-md"
        @input="editResSection($event.target.value)"
      />
    </div>

    <!-- URL and Preview Toggle Button -->
    <div class="flex justify-between">
      <div class="flex items-center">
        <span class="font-semibold w-36 text-xs">URL :</span>
        <span class="break-all underline">{{ remoteUrl }}</span>
      </div>

      <!-- Button to toggle preview visibility -->
      <button
        @click="togglePreview"
        class="self-settings-add-btn flex items-center px-4 py-2 my-2 text-white bg-blue-400 hover:bg-blue-500 rounded text-xs"
      >
        <span class="mr-2">{{ preview ? "Hide" : "Show" }} Preview</span>
        <i
          :class="
            preview ? 'fas fa-chevron-up fa-xs' : 'fas fa-chevron-down fa-xs'
          "
        ></i>
      </button>
    </div>

    <!-- Zoom View for JSON Response Preview -->
    <ZoomView v-if="preview">
      <template v-slot="{ modal }">
        <div
          :class="{
            'w-full overflow-auto json-viewer text-left': true,
            'h-72': !modal,
            'h-full': modal,
          }"
        >
          <JsonViewer v-if="resp" :value="resp" @click="handleJVDraggable" />
        </div>
      </template>
    </ZoomView>
  </div>
</template>

<script>
import ZoomView from "@/ui/ZoomView.vue";

export default {
  name: "PreviewTab",
  components: {
    ZoomView,
  },
  props: ["item"],
  data() {
    return {
      preview: false, // Track visibility of the preview
      resp: null, // Store the fetched response
      error: null, // Store error in case of failed request
    };
  },
  computed: {
    // Computed property to generate the full URL with replaced params
    remoteUrl() {
      return this.item.resolve.url_params.reduce((url, param) => {
        const re = new RegExp(`:${param.name}`, "g");
        return url.replace(re, param.default_value);
      }, this.item.resolve.source_url);
    },
    // Computed property to generate headers for the request
    remoteHeaders() {
      return (this.item.resolve.headers || []).reduce((acc, header) => {
        acc[header.name] = header.default_value;
        return acc;
      }, {});
    },
    // Computed property to generate body parameters for the request
    remoteBody() {
      return (this.item.resolve.body_params || []).reduce((acc, item) => {
        acc[item.name] = item.default_value;
        return acc;
      }, {});
    },
  },
  watch: {
    // Watcher to reset preview visibility when URL changes
    remoteUrl() {
      this.preview == false;
    },
  },
  methods: {
    /**
     * Method to edit the response section.
     * @param {string} section - The new section value for the response.
     */
    editResSection(section) {
      const clone = JSON.parse(JSON.stringify(this.item));
      clone.resolve.section = section;
      this.$emit("update:item", clone);
    },

    /**
     * Toggle the preview visibility.
     */
    togglePreview() {
      this.preview = !this.preview;
      if (this.preview) {
        this.loadResp();
      }
    },

    /**
     * Load the response data from the remote URL.
     * Sends an HTTP request based on the method (GET/POST) and data.
     */
    async loadResp() {
      const requestParams = {
        method: this.item.method, // Use the method (GET or POST)
        headers: this.remoteHeaders, // Include headers for the request
      };

      // If method is POST, include the body parameters
      if (this.item.method === "POST") {
        requestParams["body"] = JSON.stringify(this.remoteBody);
      }

      // Fetch the response and parse as JSON
      const res = await fetch(this.remoteUrl, requestParams).then((res) =>
        res.json()
      );
      this.resp = res; // Store the response in the data
      setTimeout(() => {
        this.handleJVDraggable(); // Enable dragging after loading response
      }, 1500);
    },

    /**
     * Handle drag start event for JSON keys in the viewer.
     * @param {DragEvent} ev - The drag event triggered on a JSON key element.
     */
    handleDragStart(ev) {
      ev.dataTransfer.setData(
        "text/plain",
        `{{ ${ev.target.innerText.replace(/:/g, "")} }}`
      );
    },

    /**
     * Make JSON keys draggable by attaching dragstart event listeners.
     */
    handleJVDraggable() {
      document.querySelectorAll(".jv-key").forEach((elem) => {
        elem.setAttribute("draggable", true);
        elem.removeEventListener("dragstart", this.handleDragStart); // Clean up previous event listeners
        elem.addEventListener("dragstart", this.handleDragStart); // Add the new event listener
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.json-viewer {
  ::v-deep {
    .jv-code {
      padding: 0;
    }
    .jv-key {
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
